<template>
  <div>
    <Card>
      <template #table-column="{ item2 }">
        <template v-if="item2.contractor">
          <v-avatar size="24" class="me-2 bg-gradient-default">
            <span class="text-white text-xs">{{
              item2.contractor.name[0]
            }}</span>
          </v-avatar>
          <span class="text-xs">{{ item2.contractor.name }}</span>
        </template>
      </template>
    </Card>
  </div>
</template>
    <script>
import { mapActions, mapGetters } from "vuex";
import Card from "../Components/Card.vue";
export default {
  name: "Dashboard",
  components: {
    Card,
  },
  data() {
    return {
      header: [
        {
          text: this.$i18n.t("project.project_name"),
          value: "project_name",
          align: "center",
        },
        {
          text: this.$i18n.t("employee"),
          value: "employee",
          align: "center",
        },
        {
          text: this.$i18n.t("daily.count_hour"),
          value: "hour_count",
          align: "center",
        },
      ],
      card: {
        title: this.$i18n.t("dailyReport"),
        add_url: "",
        permission: "",
      },
      load_excel: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["str_per"]),
    classLangBtn() {
      return {
        "mr-6": this.$vuetify.rtl,
        "ml-6": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    ...mapActions("report", ["getData"]),

    classBtn(val) {
      return this.$store.commit("table/SET_CHECK_PER", val);
    },

    set_data() {
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_COLLECTION", "reports");
      this.$store.commit("table/SET_LOADING", true);
      this.$store.commit("table/SET_HEADERS", this.header);
      this.$store.commit("table/SET_BTNS", this.btns);
      this.$store.commit("table/SET_PAGINATION", false);
      this.$store.commit("SET_URL", "reports/admin-daily");
    },
  },
  created() {
    console.log(this.$route.params.message);
    if (this.$route.params.message) {
      this.$store.commit("form/SET_NOTIFY", this.$route.params.message);
    }
  },
  mounted() {
    // this.getUsers();
    this.set_data();
    this.getData({ reset: true });
    document.title = this.$i18n.t("dailyReport");
  },
};
</script>
    